import React from 'react';
import Fade from '@/components/common/fade';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';
import { GatsbyImage } from 'gatsby-plugin-image';

const Speakers = ({ data }) => {
  return (
    <div className="pb-24 pt-16 md:pt-28 lg:pb-36">
      <div
        className={`${
          Array.from(data.speakers).length % 2 === 0
            ? 'max-w-md md:max-w-xl lg:max-w-4xl xl:max-w-6xl'
            : 'max-w-md md:max-w-3xl lg:max-w-4xl'
        } mx-auto`}
      >
        <Fade>
          <h2 className="text-center text-2xl md:text-[32px] lg:text-[40px]">
            {data.title}
          </h2>
        </Fade>
        <div className="-mx-2 mt-14 flex flex-wrap justify-around lg:-mx-4">
          {data.speakers &&
            data.speakers.map(({ role, name, link, company, image }, index) => {
              return (
                <div className="mb-6 w-64 px-2 md:w-56 lg:w-72 lg:px-4">
                  <div className="rounded-lg bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-0.5 shadow-feature">
                    <div className="rounded-md bg-white p-5 text-center text-sm shadow-feature lg:p-8 lg:text-base">
                      {link ? (
                        <OutboundLink
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transition duration-300 hover:opacity-80"
                        >
                          <GatsbyImage
                            className="mx-auto mb-6 h-28 w-28 rounded-full bg-purple-100"
                            image={image.asset.gatsbyImageData}
                            alt={name}
                          />
                        </OutboundLink>
                      ) : (
                        <GatsbyImage
                          className="mx-auto mb-6 h-28 w-28 rounded-full bg-purple-100"
                          image={image.asset.gatsbyImageData}
                          alt={name}
                        />
                      )}

                      <div className="-mx-2 font-bold">{name}</div>
                      {role && <div className="-mx-2">{role}</div>}
                      {company && <div>{company}</div>}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Speakers;
